import SETTING from '../enums/setting';
import THEME from '../enums/theme';
import { APP_NAME } from '../config/index'

const currentTimestamp = () => new Date().getTime()

export function loadUserStorage() {
  const localStorageName = `${APP_NAME}.user`
  let newLocalStorageData = {} as any
  const tempLocalStorage = localStorage.getItem(localStorageName)
  if (tempLocalStorage) {
    const tempData = JSON.parse(tempLocalStorage)
    newLocalStorageData = { ...tempData }
  }
  if (!newLocalStorageData.hasOwnProperty('createdTimestamp')) {
    const current = currentTimestamp()
    newLocalStorageData.createdTimestamp = current
    newLocalStorageData.lastUpdateTimestamp = current
  }
  return newLocalStorageData
}

function saveLocalStorage(localStorageData: any, localStorageName: string) {
  localStorageData.lastUpdateTimestamp = currentTimestamp()
  localStorage.setItem(localStorageName, JSON.stringify(localStorageData))
}

export function getMute() {
	const localStorageData = loadUserStorage()
	const mute = localStorageData.mute ?? false

	return mute
}

export function getTheme() {
	const localStorageData = loadUserStorage()
	const theme = localStorageData.theme ?? THEME.LIGHT_MODE

	return theme
}



export function toggleTheme() {
	const tempData = loadUserStorage()
  const theme = getTheme()
  tempData.theme = !theme || theme === THEME.LIGHT_MODE ? THEME.SPACE_MODE : THEME.LIGHT_MODE

  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function toggleMute() {
  const tempData = loadUserStorage()
  const mute = getMute()
  tempData.mute = !mute || !getMute()

  saveLocalStorage(tempData, `${APP_NAME}.user`)
}
